<template>
  <div class="container d-flex flex-column">
    <div class="row align-items-center justify-content-center g-0 min-vh-100">
      <div class="col-lg-7 col-md-8 py-8 py-xl-0">
        <div class="card shadow">
          <div class="card-body p-6">
            <div class="mb-4 text-center">
              <router-link :to="{name: 'Home'}" v-if="school.thumbnail">
                <img :src="school.thumbnail.link" class="mb-4 avatar-xxl rounded bg-white object-fit-cover" alt="">
              </router-link>
              <h1 class="mb-1 fw-bold">Đăng ký</h1>
              <span>Bạn đã có tài khoản?&nbsp;
                 <router-link :to="{name: 'Login'}" class="ms-1">Đăng nhập</router-link>
              </span>
            </div>
            <form v-on:submit.prevent="signup" autocomplete="off">
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="name" class="form-label">Họ tên (*)</label>
                    <input type="text" v-model="name" id="name" class="form-control" name="name" placeholder="Họ và tên của bạn"
                           required />
                  </div>
                  <div class="mb-3">
                    <label for="email" class="form-label">Email (*)</label>
                    <input type="email" v-model="email" id="email" class="form-control" name="email" placeholder="Địa chỉ email"
                           required />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3">
                    <label for="phone" class="form-label">SĐT (*)</label>
                    <input type="text" v-model="phone" id="phone" class="form-control" name="phone" placeholder="Số ĐT"
                           required />
                  </div>
                  <div class="mb-3">
                    <label for="password" class="form-label">Mật khẩu (*)</label>
                    <input type="password" autocomplete="off" v-model="password" id="password" class="form-control" name="password" placeholder=""
                           required />
                  </div>
                </div>
              </div>
              <div>
                <div class="d-grid">
                  <button type="submit" class="btn btn-primary" :disabled="disabled">
                    Đăng ký
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal id="signupModal" title="Đăng ký" :type="messageModalType" :message="messageModalMessage" />
</template>

<script>
import ApiService from "@/core/services/api.service";
import Modal from "@/components/Modal";
import {openModal} from "@/core/services/utils.service";

export default {
  name: 'Register',
  props: {
    school: Object
  },
  components: {
    Modal
  },
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      password: "",
      disabled: false,
      messageModalType: "",
      messageModalMessage: "",
    }
  },
  methods: {
    signup() {
      this.disabled = true;
      this.message = "";
      let query = `mutation($name: String!, $email: String!, $password: String!, $phone: String) {
        signup(name: $name, email: $email, password: $password, phone: $phone) {
          uid
        }
      }`;
      ApiService.graphql(query, {name: this.name, email: this.email, password: this.password, phone: this.phone})
          .then(({data}) => {
            if (data.data && data.data.signup) {
              this.disabled = false;
              this.messageModalMessage = "Xin chúc mừng, bạn đã đăng ký thành công!";
              this.messageModalType = "success";
              openModal("signupModal");
              this.$router.push({name: "Login"});
            } else {
              this.disabled = false;
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("signupModal");
            }
          })
          .catch((response) => {
            this.disabled = false;
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("signupModal");
          });
    }
  }
}
</script>
